<template>
  <validation-observer
    ref="observer"
    #default="{ handleSubmit }"
  >
    <b-form
      @submit.prevent="handleSubmit(validateForm)"
    >
      <b-card
        no-body
      >
        <b-card-header class="pb-50">
          <h5>
            Permissions
          </h5>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col
              md="6"
            >
              <!-- Field: Templat Name -->
              <b-form-group
                label="Template Name"
                label-for="templatename"
              >
                <validation-provider
                  #default="{ errors }"
                  name="templatename"
                  rules="required"
                >
                  <b-form-input
                    id="templatename"
                    v-model="theTemplatename"
                    :state="errors.length > 0 ? false:null"
                    name="templatename"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
        <b-table
          striped
          responsive
          :items="permissionsData"
          class="mb-0"
        >

          <template #cell(module)="data">
            {{ data.value }}
          </template>
          <template #cell()="data">
            <b-form-checkbox
              :id="data.value.name"
              v-model="selected"
              :name="data.value.name"
              :checked="data.value.value"
              :value="data.value.checkedvalue"
              :unchecked-value="data.value.uncheckedvalue"
              @change="updatePermissions()"
            />
          </template>

        </b-table>
        <b-card-body>
          <b-row>
            <b-col
              md="2"
            >
              <!-- Action Buttons -->
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                block
                type="submit"
              >
                Save Changes
              </b-button>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import {
  BCard, BTable, BCardBody, BCardHeader, BFormCheckbox, BRow, BCol, BFormGroup, BFormInput, BForm, BButton,
} from 'bootstrap-vue'
// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

import ability from '../../../../libs/acl/ability'

export default {
  components: {
    BCard,
    BTable,
    BCardBody,
    BFormCheckbox,
    BCardHeader,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      selected: [],
      theTemplatename: '',
      permissionsData: [],
      required,
    }
  },
  setup() {
    const roleOptions = store.state.appSettings.cognito_group

    return {
      roleOptions,
    }
  },
  created() {
    console.log('created')
    const abilitiesOptions = store.state.appSettings.abilities_options
    // Remove Developer abilities if in staging or production
    if (process.env.VUE_APP_ENVIRONMENT_NAME === 'staging' || process.env.VUE_APP_ENVIRONMENT_NAME === 'production') {
      abilitiesOptions.forEach((item, index) => {
        if (item.subject === 'developer') {
          abilitiesOptions.splice(index, 1)
        }
      })
    }
    let tmp = {}

    abilitiesOptions.forEach(item => {
      tmp = { module: item.title }
      tmp.read = {
        name: `${item.subject}-read`, value: `${item.subject}-read-false`, checkedvalue: `${item.subject}-read-true`, uncheckedvalue: `${item.subject}-read-false`,
      }
      tmp.create = {
        name: `${item.subject}-create`, value: `${item.subject}-create-false`, checkedvalue: `${item.subject}-create-true`, uncheckedvalue: `${item.subject}-create-false`,
      }
      tmp.update = {
        name: `${item.subject}-update`, value: `${item.subject}-update-false`, checkedvalue: `${item.subject}-update-true`, uncheckedvalue: `${item.subject}-update-false`,
      }
      tmp.delete = {
        name: `${item.subject}-delete`, value: `${item.subject}-delete-false`, checkedvalue: `${item.subject}-delete-true`, uncheckedvalue: `${item.subject}-delete-false`,
      }
      this.permissionsData.push(tmp)
    })
  },
  methods: {
    updatePermissions() {
      const abilitiesOptions = store.state.appSettings.abilities_options
      // Remove Developer abilities if in staging or production
      if (process.env.VUE_APP_ENVIRONMENT_NAME === 'staging' || process.env.VUE_APP_ENVIRONMENT_NAME === 'production') {
        abilitiesOptions.forEach((item, index) => {
          if (item.subject === 'developer') {
            abilitiesOptions.splice(index, 1)
          }
        })
      }
      const abilities = []
      let foundActions = false
      let tmp = {}
      // sort the list of selected abilities and loop through to format in final JSON format before saving
      this.selected.sort()
      abilitiesOptions.forEach(item => {
        tmp = { subject: item.subject, actions: [] }
        foundActions = false
        this.selected.forEach(item1 => {
          const parts = item1.split('-')
          if (parts[0] === item.subject) {
            tmp.actions.push({ action: parts[1] })
            foundActions = true
          }
        })
        if (foundActions) {
          abilities.push(tmp)
        }
      })
      return abilities
    },
    validateForm() {
      if (ability.can('create', 'abilitiestemplates')) {
        this.$refs.observer.validate().then(success => {
          if (success) {
            const theTemplateData = { templatename: this.theTemplatename }
            // Update the user data before sending
            theTemplateData.templateJSON = this.updatePermissions()
            console.log(theTemplateData)
            // Save the new template
            store.dispatch('app-abilities-templates/addTemplate', theTemplateData)
              .then(response => {
                console.log(response)
                // Setup the list to refresh on next view
                // this.$store.state.abilitiestemplates.fetchingAbilitiesTemplatesList = true
                const nextRout = { name: 'apps-abilities-list' }
                // Route user
                this.$router.replace(nextRout)
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'Permissions Template Added',
                        icon: 'CheckIcon',
                        variant: 'success',
                      },
                    })
                  })
              })
              .catch(error => {
                console.log(error)
                let theErrorStr = 'The following error(s) where received:\n'
                if (typeof error.response.data.errors !== 'undefined') {
                  error.response.data.errors.forEach(item => {
                    theErrorStr += `${item.message}\n`
                  })
                }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error adding Permissions Template.',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: theErrorStr,
                  },
                })
              })
          }
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
